import novelaTheme from "@narative/gatsby-theme-novela/src/gatsby-plugin-theme-ui";

export default {
  ...novelaTheme,
  initialColorMode: `dark`,
  colors: {
    ...novelaTheme.colors,
    primary: "#000",
    secondary: "#73737D",
    accent: "#6166DC",
    grey: "#73737D",
    background: " rgba(243, 244, 246,1)",
    modes: {
      dark: {
        ...novelaTheme.colors.modes.dark,
        accent: "#3fbd71",
        background: 'rgba(17, 24, 39, 1)',
        },
    },
  },
};
