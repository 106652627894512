module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-theme-ui@0.2.53_gatsby@2.32.13+theme-ui@0.2.52/node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-mdx@1.10.1_20907dfa5e7456b3e10ff58769a7061d/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":10000,"linkImagesToOriginal":false,"quality":80,"withWebp":true}},{"resolve":"@raae/gatsby-remark-oembed","options":{"providers":{"include":["Instagram"]}}},{"resolve":"gatsby-remark-embed-video","options":{"width":680,"ratio":1.77,"height":400,"related":false,"noIframeBorder":true,"urlOverrides":[{"id":"youtube"}]}},{"resolve":"gatsby-remark-copy-linked-files"},{"resolve":"gatsby-remark-numbered-footnotes"},{"resolve":"gatsby-remark-smartypants"},{"resolve":"gatsby-remark-external-links","options":{"target":"_blank","rel":"noreferrer"}}],"remarkPlugins":[null],"defaultLayouts":{},"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo"},
    },{
      plugin: require('../node_modules/.pnpm/@narative+gatsby-theme-novela@0.18.7_react-dom@16.14.0+react@16.14.0/node_modules/@narative/gatsby-theme-novela/gatsby-browser.js'),
      options: {"plugins":[],"contentPosts":"content/posts","contentAuthors":"content/authors","basePath":"/","authorsPage":true,"sources":{"local":true}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@2.12.1_gatsby@2.32.13/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Modfy Blog","short_name":"Modfy Blog","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"standalone","icon":"src/assets/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5156037bf035661e465a90b0bcc64980"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-netlify-cms@4.10.1_7f506837ab87cab6a4ab9e7565b0c4c9/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
